import { render, staticRenderFns } from "./kitsDayStudent.vue?vue&type=template&id=a22b06e8&scoped=true"
import script from "./kitsDayStudent.vue?vue&type=script&lang=js"
export * from "./kitsDayStudent.vue?vue&type=script&lang=js"
import style0 from "./kitsDayStudent.vue?vue&type=style&index=0&id=a22b06e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a22b06e8",
  null
  
)

export default component.exports